import { AuthStore, Login } from '@vaettyr/boltcave-auth-client';
import { modalService, toastService, toastStyle } from '@vaettyr/boltcave-client-core';
import EditGame from '../components/game/modals/game.edit';
import GameTemplate from '../components/game/modals/game.template';
import GameStore from '../stores/gameStore';
import GamePermission from '../components/game/modals/game.permission';
import PlayerPreference from '../components/player/modals/player.modal.preference';
import PlayerStore from '../stores/playerStore';
import { Event, Game, Player } from '../type';
import { checkinKeyProps } from '../hooks/useCheckinKey';
import { joinGameToasts, checkinToasts, rsvpToasts } from '../utility/toast.utility';
import { ReactNode, useEffect, useState } from 'react';
import ContentStore from '../stores/contentStore';
import EditContent from '../components/content/content.edit.view';
import { modalName } from '../components/content/content.edit.controller';
import PlayerErrorModal, { modalName as errorModalName } from '../components/player/modals/player.modal.error';
import axios, { AxiosError } from 'axios';

type authCheckProps = {
    authstore?: AuthStore,
    gamestore?: GameStore,
    modalservice?: modalService,
    playerstore?: PlayerStore,
    toastservice?: toastService,
    contentstore?: ContentStore,
    checkin?: checkinKeyProps
}

export type actionArgs = {
    action: "checkin"|"join"|"waitlist"|"addgame"|"addtopic",
    event: Event,
    game?: Game,
    player?: Player & { user?: number }
}

const actionKey = "DELAYED-ACTION";

export default ({ authstore, checkin, gamestore, contentstore, modalservice, playerstore, toastservice }: authCheckProps) => {
    const { authenticated } = authstore ?? {};
    const { isActive } = modalservice ?? {};
    const { isPositionEnabled, coords } = checkin ?? {};

    const [wasOpen, setWasOpen] = useState<boolean>(false);
    const [checkinArgs, setCheckinArgs] = useState<{event?:Event, game?:Game}>({});

    useEffect(() => {
        if(isPositionEnabled && coords) {
            const { success, failure } = checkinToasts(toastservice, checkinArgs.event?.name);
            playerstore?.checkin(checkinArgs.event as Event, checkinArgs.game, { coords: { lat: coords.latitude, long: coords.longitude, acc: coords.accuracy } })
            .then(success).catch(failure);
        } else if(!isPositionEnabled && isPositionEnabled !== null && !coords) {
            toastservice?.show({
                message: "Checkin not available at this time. Please enable location on your device or see an Admin",
                style: toastStyle.warning,
                dismissable: true,
                lifespan: -1
            });
        }
    }, [isPositionEnabled, coords]);

    useEffect(() => {
        const delayedAction = localStorage.getItem(actionKey);
        if(authenticated && delayedAction) {
            const args = JSON.parse(delayedAction);
            localStorage.removeItem(actionKey);
            execute(args);
        }
    }, [authenticated]);

    useEffect(() => {
        if(!isActive && wasOpen) {
            localStorage.removeItem(actionKey);
        }
        setWasOpen(isActive ?? false);
    }, [isActive]);

    const execute = ({action, event, game, player}:actionArgs) => {
        const { user } = authstore ?? {};
        switch(action) {
            case "checkin":
                const { key, getPosition } = checkin ?? {};
                if(!!key) {
                    const { success, failure } = checkinToasts(toastservice, event?.name);
                    playerstore?.checkin(event, game, { checkinKey: key })
                    .then(success).catch(failure);
                } else if(getPosition) {
                    setCheckinArgs({event, game});
                    getPosition();
                }
                break;
            case "addtopic":
                const canAddTopics = authstore?.hasPermission("ENUM.ContentService.CanEditContent", "OWN") || authstore?.hasPermission("ENUM.ContentService.CanEditContent", "ALL");
                if (canAddTopics) {
                    modalservice?.show({
                        body: <EditContent type="topic" linkType='event' parent={event.id} />,
                        key: modalName,
                        options: { className: modalName }
                    });
                } else {
                    toastservice?.show({
                        message: "You do not have permission to propose topics",
                        style: toastStyle.danger,
                        dismissable: true,
                        lifespan: 10
                    });
                }
                break;
            case "addgame":
                const self = playerstore?.players.find((p) => p.user?.id === user?.id && p.event?.id === event?.id);
                const canAddOthers = authstore?.hasPermission("ENUM.GameService.CanEditGames", "ALL");
                const canAdd = canAddOthers || (authstore?.hasPermission("ENUM.GameService.CanEditGames", "OWN") && !self?.host);
                const host = canAddOthers ? user : undefined;
                const modal = canAdd ? ( Object.keys(gamestore?.templates ?? {}).length && !gamestore?.createSupressed ?
                {
                    body: <GameTemplate event={event} mode="load" host={host}/>,
                    options: { className: "game-template" },
                    key: "game-template"
                }:
                {
                    body: <EditGame event={event} host={host} isModal/>,
                    options:{className:"edit-game"},
                    key:"edit-game"
                }) : {
                    body: <GamePermission isHost={!!self?.host} />,
                    options: { className: "game-permission" },
                    key: "game-permission"
                }
                modalservice?.show(modal);
                break;
            case "join":
            case "waitlist":
                const onWaitlist = action === 'waitlist';
                // does this game have multiple systems?
                if((game?.systems?.length ?? 0) > 1) {
                    modalservice?.show({
                        body:<PlayerPreference game={game as Game} event={event} user={user} waitlist={onWaitlist} />,
                        options:{ className: "player-preference" },
                        key:'player-preference'
                    });
                } else {
                    const { success: joinSuccess, failure: joinFailure } = !!game ? joinGameToasts(toastservice, game?.title, onWaitlist) : rsvpToasts(toastservice, event?.name);
                    playerstore?.join(event, game, player, onWaitlist)
                        .then(({deleted}) => {
                            if(!!deleted) {
                                gamestore?.clear(deleted);
                            }
                            joinSuccess();
                        }).catch((err: Error | AxiosError) => {
                            if (axios.isAxiosError(err) && err.response?.status === 409) {
                                // game is full. Refresh the game and show a modal
                                playerstore?.fetch(event, game);
                                modalservice?.show({
                                    body: <PlayerErrorModal event={event} game={game} player={player} />,
                                    options: { className: errorModalName },
                                    key: errorModalName
                                });
                            } else {
                                joinFailure();
                            }
                        });
                }
                break;
            default:
                break;
        }
    }

    return (args: actionArgs, message?: ReactNode) => {
        if(authenticated) {
            execute(args);
        } else {
            localStorage.setItem(actionKey, JSON.stringify(args));
            modalservice?.show({
                body: <Login isModal message={message} />,
                options: { className: "login" },
                key: "login"
            });
        }
    }
}