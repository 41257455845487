import { observable, action, computed,  makeObservable, runInAction } from 'mobx';
import axios, { AxiosError } from 'axios';
import { AuthStore, User } from '@vaettyr/boltcave-auth-client';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { Game, Event, Player } from '../type';
import { fromString } from '../utility/date.utility';

export type HistoryGame = Game & { player: Player, self?: Player }

type PlayerHistory = {
  count: number,
  history: HistoryGame[]
}

export default class PlayerStore
{
  @observable busy:boolean = false;
  @observable players: Player[] = [];
  @observable users: User[] = [];
  @observable details: Record<string, PlayerHistory> = {};

  private route:string;

  constructor()
  {
      makeObservable(this);
      this.route = storeHelpers.GetEndpoint("RPGService");
  }

  @computed get games (): Record<number, {host:Player, players:Player[]}> {
    const games = this.players.flatMap((p) => [p.game?.id, p.host?.id, ...(p.waitlist ?? [])]).filter((i, index, set) => i !== undefined && index !== undefined && index === set.indexOf(i));
    const mapped = games.map((id) => {
      const host = this.players.find((p) => p.host?.id === id);
      const players = this.players.filter((p) => (p.game?.id === id && (!p.host || p.host?.id !== id)) || p.waitlist?.includes(id as number) );
      players.sort((a, b) => {
        const aStatus = a.game?.id === id ? (a.checkedIn ? 3 : 2) : (a.checkedIn ? 1: 0);
        const bStatus = b.game?.id === id ? (b.checkedIn ? 3 : 2) : (b.checkedIn ? 1: 0);
        return bStatus - aStatus;
      });
      return [id, { host, players }];
    })
    return Object.fromEntries(mapped);
  }

  @action set = (players:Player[]) => {
    this.players = players;
  }

  @action add = (player?:Player) => {
    if(!player) return;
    const index = this.players.findIndex((p) => p.id === player.id);
    if(index >= 0) {
      this.players = [...this.players.slice(0, index), player, ...this.players.slice(index + 1)];
    } else {
      this.players = [...this.players, player];
    }
  }

  @action fetch = async(event:Event|Event[], game?:Game):Promise<Game[]> =>
  {
    if(!event || (Array.isArray(event) && !event.length) || this.busy) return [];
    this.busy = true;
    const path = Array.isArray(event) ? `/${event[0].id}` : `/${event.id}`;
    const query = Array.isArray(event) ? `?${event.map(e => `event=${e.id}`).join('&')}`:'';
    return new Promise((resolve, reject) => {
        axios.get(`${this.route}api/v1/player${path}${game?.id ? `/${game.id}`:''}${query}`, AuthStore.GetConfig())
        .then(response => {
            const { data = [] }:{ data: Player[] } = response;
            runInAction(() => {
                this.players = data;
            });
            resolve(data ?? []);
        })
        .catch(err => reject(err))
        .finally(() => {
            runInAction(() => { this.busy = false; })
        });
    });
  }

  @action history = async(user:User): Promise<Game[]> => {
    const userId = user.id?.toString() ?? "0";
    let paging = '';
    if(Object.keys(this.details).includes(userId)) {
      const target = this.details[userId];
      if(target.history.length >= target.count) {
        return new Promise((resolve) => resolve(target.history));
      }
      paging = `?pagesize=10&page=${target.history.length/10}`;
    };
    this.busy = true;
    return new Promise((resolve, reject) => {
      axios.get<PlayerHistory>(`${this.route}api/v1/player/user/${userId}${paging}`, AuthStore.GetConfig())
        .then((response) => {
          const { data } = response;
          const history = data.history.map((entry) => {
            if(entry.event && typeof(entry.event) === 'object' && entry.event.eventDate && typeof(entry.event.eventDate) === 'string') {
              entry.event.eventDate = fromString(entry.event.eventDate);
            }
            return entry;
          })
          runInAction(() => {
            if(!Object.keys(this.details).includes(userId)) {
              this.details[userId] = { count: data.count, history };
            } else {
              this.details[userId].history = [...this.details[userId].history, ...history];
            }
            resolve(history);
          });
        })
        .catch((err) => { reject(err); })
        .finally(() => runInAction(() => { this.busy = false; }));
    })
  }

  @action getUsers = async(search: string): Promise<User[]> => {
    this.busy = true;
    return new Promise((resolve, reject) => {
      axios.get(`${this.route}api/v1/player/users?search=${search}`, AuthStore.GetConfig())
        .then(({ data: users }: { data: User[] }) => {
          runInAction(() => {
            this.users = users;
            resolve(users);
          })
        })
        .catch((err) => reject(err))
        .finally(() => runInAction(() => this.busy = false));
    });
  }

  @action join = async(event:Event, game?:Game, payload?:Player & { user?: number }, waitlist?:boolean):Promise<{player: Player, deleted?: Game}> => {
    this.busy = true;
    return new Promise((resolve, reject) => {
      const path = `${event.id}${game?.id ? `/${game.id}` : ''}${waitlist ? '?waitlist=true' : ''}`;
      axios.put(`${this.route}api/v1/player/${path}`, payload, AuthStore.GetConfig())
        .then(({data}:{data:{ player: Player, deleted?: Game }}) => {
          const { player } = data;
          const index = this.players.findIndex((p) => p.id === player?.id);
          runInAction(() => {
            if(index < 0 ) {
              this.players = [...this.players, player];
            } else {
              this.players = [...this.players.slice(0, index), player, ...this.players.slice(index + 1)];
            }
          });
          resolve(data);
        })
        .catch((err: Error | AxiosError) => reject(err))
        .finally(() => {
          runInAction(() => this.busy = false)
        });
    });
  }

  @action setPreference = async(event: Event, game?:Game, payload?: Player, waitlist?: boolean): Promise<Player> => {
    this.busy = true;
    return new Promise((resolve, reject) => {
      const path = `${event.id}${game?.id ? `/${game.id}` : ''}${waitlist ? '?waitlist=true' : ''}`;
      axios.post(`${this.route}api/v1/player/${path}`, payload, AuthStore.GetConfig())
        .then(({data:player}:{data:Player}) => {
          const index = this.players.findIndex((p) => p.id === player.id);
          runInAction(() => {
            if(index < 0) {
              this.players = [...this.players, player];
            } else {
              this.players = [...this.players.slice(0, index), player, ...this.players.slice(index + 1)];
            }
          });
          resolve(player);
        })
        .catch((err) => reject(err))
        .finally(() => {
          runInAction(() => this.busy = false)
        });
    });
  }

  @action toggleWaitlist = async(event: Event, game?:Game, player?:Player): Promise<{player: Player, deleted?: Game}> => {
    this.busy = true;
    const waitlist = player?.waitlist?.includes(game?.id ?? 0) ?? false;
    return new Promise((resolve, reject) => {
      const path = `${event.id}${game?.id ? `/${game.id}` : ''}${!waitlist ? '?waitlist=true' : ''}`;
      axios.put(`${this.route}api/v1/player/${path}`, player, AuthStore.GetConfig())
        .then(({ data }:{data:{ deleted?: Game, player: Player}}) => {
          const { deleted, player: toggledPlayer } = data;
          const index = this.players.findIndex((p) => p.id === toggledPlayer.id);
          runInAction(() => {
            if(index < 0) {
              this.players = [...this.players, toggledPlayer];
            } else {
              this.players = [...this.players.slice(0, index), toggledPlayer, ...this.players.slice(index + 1)];
            }
          });
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => {
          runInAction(() => this.busy = false)
        });
    });
  }

  @action leave = async(event:Event, game?:Game, payload?:Player, waitlist?: boolean):Promise<{player: Player, deleted?: Game}|boolean> => {
    this.busy = true;
    return new Promise((resolve, reject) => {
      const query:string[] = [];
      if(payload && payload.id) { query.push(`id=${payload.id}`); }
      if(waitlist) { query.push(`waitlist=true`); }
      const queryString = query.length > 0 ? `?${query.join('&')}` : '';
      const path = `${event.id}${game?.id ? `/${game.id}` : ''}${queryString}`;
      axios.delete(`${this.route}api/v1/player/${path}`, AuthStore.GetConfig())
        .then(({data}:{data:{player:Player, deleted?:Game}|string}) => {
          if(typeof(data) === 'string' && data.toLowerCase() === 'removed') {
            const removeIndex = this.players.findIndex((p) => p.id === payload?.id ); // add user to this as a fallback
            if(removeIndex >= 0) {
              runInAction(() => {
                this.players = [...this.players.slice(0, removeIndex), ...this.players.slice(removeIndex + 1)];
                resolve(true);
              })
            } else { reject(); }
          } else if(typeof(data) === 'object') {
            const { player } = data;
            const index = this.players.findIndex((p) => p.id === player.id);
            runInAction(() => {
              if(index < 0) {
                this.players = [...this.players, player];
              } else {
                this.players = [...this.players.slice(0, index), player, ...this.players.slice(index + 1)];
              }
            });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((err) => reject(err))
        .finally(() => {
          runInAction(() => this.busy = false)
        });
    });
  }

  @action checkin = async(event:Event, game?:Game, payload:Player|{ checkinKey?:string, waitlist?:boolean, coords?: {lat:number, long:number, acc:number} } = {}, checkout:boolean = false): Promise<Player|undefined> => {
    this.busy = true;
    return new Promise((resolve, reject) => {
      const path = `${event.id}${game?.id ? `/${game.id}` : ''}${checkout ? '?checkout=true' : ''}`;
      axios.patch(`${this.route}api/v1/player/${path}`, payload, AuthStore.GetConfig())
      .then((response) => {
        const { data: checkedInPlayer }: { data: Player } = response;
        const index = this.players.findIndex((p) => p.id === checkedInPlayer.id);
        runInAction(() => {
          if(index < 0) {
            this.players = [...this.players, checkedInPlayer];
          } else {
            this.players = [...this.players.slice(0, index), checkedInPlayer, ...this.players.slice(index + 1)];
          }
        });
        resolve(checkedInPlayer);
      })
      .catch((err) => reject(err))
      .finally(() => {
        runInAction(() => {
          this.busy = false;
        });
      });
    })
  }
}