import { ReactNode, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Player } from '../../../type';
import { modalService } from '@vaettyr/boltcave-client-core';
import { MediaImage } from '@vaettyr/boltcave-media-client';
import PlayerDetail, { modalName } from '../views/player.detail.view';
import usePlayerName from '../../../hooks/usePlayerName';

type PlayerViewProps = {
  player?: Player;
  className?: string;
  large?: boolean;
  modalservice?: modalService;
  children?: ReactNode | ReactNode[];
  hide?: ('status'|'name')[];
  onClick?: (() => void);
  showHistory?: boolean;
}
export default inject('modalservice') ( observer (
({ className = '', modalservice, player = {}, large = false, children, showHistory = false, hide = [], onClick }: PlayerViewProps) => {

  const [loadFailed, setLoadFailed] = useState<boolean>(false);
  if(!player) return null;
  const { user } = player;
  const userName = usePlayerName(player);
  const hideStatus = hide.includes('status');
  const hideName = hide.includes('name');

  const figureClass  = `player-avatar${!hideStatus ? (player.checkedIn ? ' confirmed' : ' responded') : ''}`;

  const showDetail = () => {
    if (onClick) {
      onClick();
    } else if(user && showHistory) {
      modalservice?.show({
        body: <PlayerDetail user={user} player={player} isModal showTokens />,
        key: modalName,
        options: { className: modalName }
      });
    }
  }

  return (
    <div className={`player-icon ${className} ${large ? ' large' : ''}${showHistory && user ? ' clickable' : ''}`} onClick={showDetail}>
      {(user?.Avatar && !loadFailed) && (
          <MediaImage src={user.Avatar} size={32} className={ figureClass } onLoadFailed={() => setLoadFailed(true)} mode='crop' rounded />
      )}
      {(!user?.Avatar || loadFailed) && (
          <span className={`icon ${figureClass}`}>
              <i className={`fa-2xl fa-solid fa-circle-user`}></i>
          </span>
      )}
      { !hideName && <div className="player-name">
        <strong>{ userName }</strong>
      </div> }
      {children}
    </div>
  )
} ));