import { Card, modalService } from "@vaettyr/boltcave-client-core";
import { inject, observer } from 'mobx-react';
import { Event, Game, Player } from '../../../type';
import AuthorizedContext from '../../../auth/authorized.context';
import { useContext } from "react";

type ErrorModalProps = {
    modalservice?: modalService,
    event: Event,
    game?: Game,
    player?: Player & { user?: number },}

export const modalName = 'player-error';

export default inject('modalservice') ( observer(
( { modalservice, event, game, player }: ErrorModalProps ) => {

    const authorizedAction = useContext(AuthorizedContext);

    const joinWaitlist = () => {
      modalservice?.hide("player-error");
      authorizedAction({ action:"waitlist", event: event as Event, game, player }, <p>You must be logged in to join a game's waitlist</p>)
    }

    const onCancel = () => {
        modalservice?.hide("player-error");
    }

    const actions = (
        <>
            <button type="button" className="card-footer-item button is-primary" onClick={joinWaitlist}>Join Waitlist</button>
            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
        </>
    );

    return (
        <Card header="Error joining game" footer={actions}>
            <p className="has-text-centered">This game has already reached the maximum number of players. You can join the waitlist instead.</p>
        </Card>
    );
}));